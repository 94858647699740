/* nav.nav {
  
} */

.nav-list {
  list-style: none;

  display: flex;
  align-items: center;
  padding: 0;
  gap: 20px;
}

.nav-list li a {
  text-decoration: none;
  color: #fff;
  transition: 0.45s all ease;
}

.nav-list li a:hover {
  cursor: pointer;
  color: #2c7ffa;
}

/* BurgerIcon.module.css */
.burgerContainer {
  display: none;
  width: 30px;
  height: 24px;
  
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 1002;
}

.line {
  width: 100%;
  height: 4px;
  background-color: #ffffff;
  border-radius: 2px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.line1.open {
  transform: rotate(45deg) translate(8px, 8px);
}

.line2.open {
  opacity: 0;
}

.line3.open {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width:1200px) {
  .burgerContainer {
    display: flex;
  }

  .nav-list {
    position: absolute;
    z-index: 100;

    flex-direction: column;
    right: 0;
    top: 0;
    margin: 0;
    align-items: start;
    height: 100vh;
    padding: 40px;
    background: #011119eb;
    right: -100%;
    transition: 0.5s all ease;
  }
  .nav-list.active {
    right: 0;
  }
}

@media (max-width: 1024px) {
  .nav-list li a {
    font-size: 14px;
  }

  .nav-list {
    gap: 15px;
  }
}
