.preloader {
  position: absolute;
  z-index: 1001;
  height: 100vh;
  width: 100vw;
  background-color: #011119;
  display: flex;
  align-items: center;
  justify-content: center;
}


.preloader-img {
  width: 300px;
}

@media (max-width: 768px) {
  .preloader-img {
    width: 200px;
    }
}