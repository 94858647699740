.importance-coin {
  padding: 50px 0 0;
  margin: 70px 0 0;
}
title-block {
  margin-bottom: 10px;
}

.importance-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin-top: 60px;
  padding: 0;
}

.importance-list__item-img {
  width: 40px;
}

.importance-list__item-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.importance-list__item-title {
  text-align: center;
}


@media (max-width: 768px) {
  .importance-list {
    flex-direction: column;
    gap: 30px;
  }
}