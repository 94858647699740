.header-block {
  height: 100vh;
  display: flex;
}

.header-block .container {
  max-width: 1440px;
  width: 100%;
  display: flex;
}

.header-block__text {
  background: none;
  margin: auto 0;
  max-width: 75%;
}

.header-block__title {
  font-size: 54px;
  font-weight: 500;
  margin-bottom: 25px;
}

.header-block__description {
  font-size: 20px;
  text-align: justify;
  max-width: 570px;
}

.video {
  width: 100%;
  object-fit: cover;
  max-height: 100vh;
  opacity: 0.25;
  position: absolute;
  z-index: -1;
}

@media (max-width: 1024px) {
  .header-block__text {
    max-width: 100%;
  }

  .header-block__title {
    font-size: 48px;
    margin-bottom: 15px;
  }

  .header-block__description {
    font-size: 18px;
  }
  .video {
    height: 100vh;
  }
}
@media (max-width: 768px) {
  .header-block__title {
    font-size: 36px;
    margin-bottom: 15px;
  }

  .header-block__description {
    text-align: left;
    font-size: 16px;
  }

  .header-block__description {
    max-width: 500px;
  }
}
@media (max-width: 425px) {

  .header-block__title { 
    font-size: 28px;
  }

  .header-block {
    height: 80vh;
  }

  .video {
    height: 80vh;
  }

  .header-block__description {
    max-width: 350px;
    font-size: 14px;
  }
}

@media (max-width: 320px) {
}
