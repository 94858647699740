.lenta {
  margin-top: 120px;
  position: relative;
}

.lenta-img {
  width: 100%;
  height: 340px;
}

.lenta-info {
  display: flex;
  gap: 160px;
}

.lenta-info__title {
  font-size: 24px;
}

@media (max-width: 768px) {
  .lenta-info {
    padding: 0 20px;
    width: 100%;
    gap: 15px;
    flex-direction: column;
  }

  .lenta-info__title {
    margin-top: 0;
  }

  .lenta-info__title { 
    font-size: 18px;
  }


}

@media (max-width: 425px) { 
  .lenta-info__title {  
    font-size: 16px;
  }

}
