/* @import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic); */
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&family=Jura:wght@300..700&display=swap');


html {
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden;
}

body {
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  
  margin: 0;
  padding: 0;

  background-color: #011119;
  color: #E0E0E0;

  font-family: "Jura", sans-serif;
  font-weight:400;

  font-size: 1rem;

  scroll-behavior: smooth;
  
  text-decoration: none;
  width: 100vw;
  overflow-x: hidden;
}

body.active-preloader {
  height: 100vh;
  overflow: hidden;
}

.container {
  max-width: 1440px;
  padding: 0 30px;

  /* width: 100%; */
}


@media (max-width: 1024px) {
  
}
@media (max-width: 768px) {
  
}
@media (max-width: 425px) {
  
}

@media (max-width: 320px) {
  
}
